import { CProgress, CProgressBar } from "@coreui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FirebaseAuth } from "../../../../../components/FirebaseAuth/firebase";
import { CLUSTER_STATUSES } from "../../../../../constants/clusters";
import AddOns from "./ClusterDetailsPanel/AddOns";
import KubeConfigModal from "./KubeConfigModal";
const Result = ({ newClusterID, showQuotaLimit, error, expiryOption }) => {
  const [status, setStatus] = useState(
    CLUSTER_STATUSES.find((x) => x.dbDefaultValue).key
  );
  const [kubeconfig, setKubeconfig] = useState("");
  const [progress, setProgress] = useState(0);
  const [clstr, setClstr] = useState({});
  const [showPopupModal, setShowPopupModal] = useState({
    open: false,
    data: null,
  });

  useMemo(() => {
    const clustersRef = FirebaseAuth.firestore().collection("clusters");
    const currentClusterRef =
      newClusterID &&
      FirebaseAuth.firestore().collection("clusters").doc(newClusterID);
    newClusterID && currentClusterRef.get().then((doc) => setClstr(doc.data()));
    newClusterID &&
      clustersRef.doc(newClusterID).onSnapshot((querySnapshot) => {
        const dbStatus = querySnapshot.data()?.status;
        currentClusterRef.get().then((doc) => setClstr(doc.data()));
        console.log(dbStatus);
        const progress = CLUSTER_STATUSES.find(
          (x) => x.key === dbStatus
        )?.progress;
        setProgress(progress);

        if (dbStatus && dbStatus !== status) {
          setStatus(dbStatus);
          querySnapshot.data() &&
            setKubeconfig(
              querySnapshot.data().kubeconfigDlink ||
                querySnapshot.data().kubeconfig
            );
        }
      });
  }, [newClusterID, status]);
  /* eslint-disable */

  useEffect(() => {
    if (showQuotaLimit.status && status !== "QUOTA_LIMIT") {
      setStatus("QUOTA_LIMIT");
    }
    if (error.status) {
      setStatus("NAME_ERROR");
    }
  }, []);
  /* eslint-enable */

  return (
    <div className="result-container">
      {progress !== 100 && (
        <CProgress className="mb-3" style={{ width: "60%" }}>
          <CProgressBar
            color="success"
            variant="striped"
            animated
            value={progress}
          />
        </CProgress>
      )}
      {progress !== 100 && <h3>cluster creation is in progress</h3>}
      <div className="card" style={{ border: "none" }}>
        <div className="card-header bd-placeholder-img card-img-top">
          <span
            style={{
              display: "inline-block",
              borderRadius: "50%",
              padding: "1.5em",
            }}
          >
            {CLUSTER_STATUSES?.find((x) => x.key === status)?.icon}
          </span>
        </div>
        <div className="card-body">
          <h3 className="card-text">
            {CLUSTER_STATUSES?.find((x) => x.key === status)?.message}
          </h3>
          <h5 className="card-caption">
            {status === "READY" &&
              expiryOption !== "Never" &&
              "Your cluster will be deleted automatically after " +
                expiryOption}
          </h5>
          {showQuotaLimit.status &&
            showQuotaLimit.messages.map((mesg, index) => (
              <h5 key={index} className="card-caption text-danger mt-2">
                {mesg}
              </h5>
            ))}
          {error.status &&
            error.messages.map((mesg, index) => (
              <h5 key={index} className="card-caption text-danger mt-2">
                {mesg}
              </h5>
            ))}
        </div>

        {CLUSTER_STATUSES?.find((x) => x.key === status)?.key === "READY" &&
          kubeconfig && (
            <div className="card-footer">
              <button
                className="btn btn-square btn-info"
                onClick={() =>
                  setShowPopupModal({ open: true, data: kubeconfig })
                }
              >
                Download Kubeconfig
              </button>
              {showPopupModal.open && (
                <KubeConfigModal
                  config={kubeconfig}
                  visible={showPopupModal.open}
                  setVisible={setShowPopupModal}
                />
              )}
            </div>
          )}
      </div>
      {clstr.features && status === "READY" && (
        <AddOns cluster={clstr} calledFrom="result" />
      )}
    </div>
  );
};

export default Result;
